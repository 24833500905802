<template>
    <div class="counter-wrap">
        <div class="container">
            <div class="counter-card-wrap">
                <div class="counter-card">
                    <div class="counter-text">
                        <div class="counter-num">
                            <span>+ 8000</span>
                        </div>
                        <p>Abonnés</p>
                    </div>
                </div>
                <div class="counter-card">
                    <div class="counter-text">
                        <div class="counter-num">
                            <span>+ 100</span>
                        </div>
                        <p>Années d'existence</p>
                    </div>
                </div>
                <div class="counter-card">
                    <div class="counter-text">
                        <div class="counter-num">
                            <span>+ 1000</span>
                        </div>
                        <p>Boites disponibles</p>
                    </div>
                </div>
                <div class="counter-card">
                    <div class="counter-text">
                        <div class="counter-num">
                            <span>+ 5000</span>
                        </div>
                        <p>Abonnements actifs</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CounterComp'
}
</script>