<template>
    <div class="page-wrapper">
        <NavBar class="style1" />
        <div class="content-wrapper">
            <PageTitle pageTitle="Transfert" />
            <ProduitTransfert />
        </div>
        <FooterComp />
    </div>
</template>

<script>
import NavBar from '../Layouts/NavBar'
import PageTitle from '../Common/PageTitle'
import ProduitTransfert from '../Services/ProduitTransfert'
import FooterComp from '../Layouts/FooterComp'

export default {
    name: 'ProduitTransfertPage',
    components: {
        NavBar,
        PageTitle,
        ProduitTransfert,
        FooterComp,
    }
}
</script>