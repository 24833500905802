<template>
    <div class="page-wrapper">
        <NavBar class="style1" />
        <div class="content-wrapper">
            <PageTitle pageTitle="Tarification Poste Lettre" />
            <ProduitPosteLettre />
        </div>
        <FooterComp />
    </div>
</template>

<script>
import NavBar from '../Layouts/NavBar'
import PageTitle from '../Common/PageTitle'
import ProduitPosteLettre from '../Services/ProduitPosteLettre'
import FooterComp from '../Layouts/FooterComp'

export default {
    name: 'ProduitPosteLettrePage',
    components: {
        NavBar,
        PageTitle,
        ProduitPosteLettre,
        FooterComp,
    }
}
</script>