<template>
    <div class="feature-wrap style2 ptb-100 bg-stratos">
        <img src="../../assets/images/section-shape-1.png" alt="Image" class="section-shape-one">
        <div class="container">
            <div class="row gx-5 align-items-center">
                <div class="col-lg-6 col-12 order-lg-1 order-2">
                    <div class="feature-content">
                        <div class="content-title style2">
                            <span>Global features</span>
                            <h2>Simplify Your Employee Expense &amp; Accounts Payable</h2>
                            <p>There are many variations of passages of Lorem Ipsum amet avoilble but majority have suffered alteration in some form, by injected humur or randomise words which don't sure amet sit dolor quras alto lorem.</p>
                        </div>
                        <div class="feature-item-wrap">
                            <div class="feature-item">
                                <div class="feature-icon">
                                    <i class="flaticon-credit-card"></i>
                                </div>
                                <div class="feature-text">
                                    <h4>Multi Card System</h4>
                                </div>
                            </div>
                            <div class="feature-item">
                                <div class="feature-icon">
                                    <i class="flaticon-airplane"></i>
                                </div>
                                <div class="feature-text">
                                    <h4>Buy Airline Ticket</h4>
                                </div>
                            </div>
                            <div class="feature-item">
                                <div class="feature-icon">
                                    <i class="flaticon-driving-license"></i>
                                </div>
                                <div class="feature-text">
                                    <h4>Expense Management</h4>
                                </div>
                            </div>
                            <div class="feature-item">
                                <div class="feature-icon">
                                    <i class="flaticon-support"></i>
                                </div>
                                <div class="feature-text">
                                    <h4>24/7 Support</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-12 order-lg-2 order-1">
                    <div class="feature-img-wrap">
                        <img src="../../assets/images/feature/feature-shape-1.png" alt="Image" class="feature-shape-one moveHorizontal">
                        <img src="../../assets/images/feature/feature-shape-2.png" alt="Image" class="feature-shape-two bounce">
                        <img src="../../assets/images/feature/feature-1.png" alt="Image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GlobalFeatures'
}
</script>