<template>
    <!-- <div class="service-wrap ptb-100">
        <div class="container">

            <div class="row ">
                <div class="col-xl-4 col-lg-6 col-md-6" v-for="(article, index) in articles" :key="index">
                    <div class="blog-card style1">
                        <div class="blog-img"  style="height:277px;">
                            <img :src="getUrlApiForFiles(article.type)" alt="Image" style="width: 100%; height:auto; object-fit: contain;">
                        </div>
                    </div>
                </div>
            </div> 
            
        </div>
    </div> -->
    <div class="service-wrap pt-20 pb-20">
        <div class="container">
            <div class="row ">
                <div class="col-xl-4 col-lg-6 col-md-6" v-for="(article, index) in articles" :key="index">
                    <div class="blog-card style1">
                        <a :href="getUrlApiForFiles(article.type)" data-lightbox="gallery">
                            <div class="blog-img" style="height:277px;">
                                <img :src="getUrlApiForFiles(article.type)" alt="Image" style="width: 100%; height:auto; object-fit: contain;">
                            </div>
                        </a>
                    </div>
                </div>
            </div> 
        </div>
    </div>
</template>

<script lang="ts">

import ApiService from "@/services/ApiService";
import { getUrlApiForFiles, error,format_date } from "@/utils/utils";
import { defineComponent, ref, onMounted } from "vue";
import { Article } from "@/models/Article";
import { useRoute } from "vue-router";

export default defineComponent({
    name: 'ViewMediatheque',
    components: {
  },
  setup(){

    const articles = ref<Array<Article>>([]);   

    function getAllMedia(id:any) {
      return ApiService.get(`/articles/medias/`+id)
        .then(({ data }) => {
          articles.value = data.data;
          console.log(articles.value);
          return data;
        })
        .catch(({ response }) => {
          error(response.message)
      });
    }

    const route = useRoute();
    
    onMounted(() => {
        const id= route.params.id
        getAllMedia(id);
    });

    return { 
        articles,
        getUrlApiForFiles,
        format_date,
        route,
    };
  },
});
</script>