import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.aproposs, (apropos) => {
      return (_openBlock(), _createElementBlock("div", {
        key: apropos.id
      }, [
        _createElementVNode("p", {
          class: "mb-20",
          innerHTML: apropos.cookie
        }, null, 8, _hoisted_2)
      ]))
    }), 128))
  ]))
}