<template>
    <div class="page-wrapper">
        <NavBar class="style1" />
        <div class="content-wrapper">
            <PageTitle pageTitle="Détails de l'article" />
            <ViewArticle />
        </div>
        <FooterComp />
    </div>
</template>

<script>
import NavBar from '../Layouts/NavBar'
import PageTitle from '../Common/PageTitle'
import ViewArticle from '../ViewArticle/ViewArticle'
import FooterComp from '../Layouts/FooterComp'

export default {
    name: 'ViewArticlePage',
    components: {
        NavBar,
        PageTitle,
        ViewArticle,
        FooterComp,
    }
}
</script>