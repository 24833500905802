<template>
    <div class="page-wrapper">
        <NavBar class="style1" />
        <MainBanner />
        <SmartBanking />
        <FeatureComp />
        <BankingSecurity />
        <ServicesComp/>
        <AppDownload />
        <TestimonialsComp />
        <PricingPlan />
        <ContactUs />
        <BlogComp />
        <FooterComp />
    </div>
</template>

<script>
import NavBar from '../Layouts/NavBar'
import MainBanner from '../HomeThree/MainBanner'
import SmartBanking from '../HomeThree/SmartBanking'
import FeatureComp from '../HomeThree/FeatureComp'
import BankingSecurity from '../HomeThree/BankingSecurity'
import ServicesComp from '../HomeThree/ServicesComp'
import AppDownload from '../HomeThree/AppDownload'
import TestimonialsComp from '../HomeThree/TestimonialsComp'
import PricingPlan from '../HomeThree/PricingPlan'
import ContactUs from '../HomeThree/ContactUs'
import BlogComp from '../HomeThree/BlogComp'
import FooterComp from '../Layouts/FooterComp'

export default {
    name: 'HomeThreePage',
    components: {
        NavBar,
        MainBanner,
        SmartBanking,
        FeatureComp,
        BankingSecurity,
        ServicesComp,
        AppDownload,
        TestimonialsComp,
        PricingPlan,
        ContactUs,
        BlogComp,
        FooterComp,
    }
}
</script>