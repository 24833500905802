<template>
      <div class="container">
          <div v-for="apropos in Aproposs" :key="apropos.id">
          <p class="mb-20" v-html="apropos.donnePersonnelle"></p>
          </div>
      </div>
</template>

<script lang="ts">
import { defineComponent,onMounted,ref } from "vue";
import ApiService from "../../services/ApiService";
import axios from "axios";
import { error } from "../../utils/utils";
import { Apropos } from "@/models/Apropos";

export default defineComponent({
  name: "DonnePersonnelle",
  components: {},
  setup() {

    const Aproposs = ref<Array<Apropos>>([]);

function getActifApropos() {
    return ApiService.get('/apropos/statut/1')
    .then(response => {
        Aproposs.value = response.data.data;
        return response.data.data;
    })
    .catch(({ response }) => {
        error(response.data.message)
    });
}

onMounted(() => {
getActifApropos();
});


return {
    Aproposs,
    getActifApropos
};
  },
});

</script>