<template>
    <div class="service-wrap pt-100 pb-75">
        <div class="container">
            <div class="row">
                <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                    <div class="section-title style1 text-center mb-40">
                        <span>Nos services</span>
                        <h2>Les services qu'offrent La Poste du Bénin SA</h2>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6" v-for="categorieArticle in CategorieArticles" :key="categorieArticle.id">
                    <div class="service-card style1">
                        <span class="service-icon">
                        <img src="../../assets/images/service/service-icon-2.png" alt="Image">
                        </span>
                        <h3 >
                        <router-link :to=categorieArticle.lienExterne v-if="categorieArticle.lienExterne">{{ categorieArticle.nom }}</router-link>
                        <router-link to="#" v-else>{{ categorieArticle.nom }}</router-link>
                        </h3>
                        
                        <p >{{ (categorieArticle.description.length > 25) ? categorieArticle.description.substring(0, 90) + '...' : categorieArticle.description }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import ApiService from "@/services/ApiService";
import { defineComponent, onMounted, ref} from "vue";
import { CategorieArticle } from "../../models/CategorieArticle";
import { error } from "../../utils/utils";
import axios from "axios";

export default defineComponent({
    name: 'ServicesComp',
    components: {},
    setup() {
        const CategorieArticles = ref<Array<CategorieArticle>>([]);
        const categorieArticle = ref<CategorieArticle>();

        function getAllService() {
            return ApiService.get('/categorieArticleChilds')
            .then(response => {
                const categorieArticleIsService = response.data.data.filter(category => category.isService === "Oui");
                CategorieArticles.value = categorieArticleIsService;
                return categorieArticleIsService;
            })
            .catch(({ response }) => {
                error(response.data.message)
            });
        }

        onMounted(() => {
            getAllService();
        });


        return {
            CategorieArticles,
            categorieArticle,
            getAllService,
        };
    }
});
</script>