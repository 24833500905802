<template>
  <div>
    <div class="hero-wrap style3 bg-squeeze">
      <div class="hero-slider-one">
        <carousel autoplay="10000" :wrap-around="true" :transition="800">
          <Slide v-for="(alaUne, index) in alaUnes" :key="index">
            <div class="hero-slide-item">
              <div class="container">
                <div class="row gx-5 align-items-center">
                  <div class="col-md-6">
                    <div
                      class="hero-content"
                      data-speed="0.10"
                      data-revert="true"
                    >
                      <span>{{ alaUne.sousTitre }}</span>
                      <h1>{{ alaUne.titre }}</h1>
                      <p v-html="alaUne.description"></p>
                      <div class="hero-btn">
                        <router-link
                          :to="{
                            name: 'ViewArticlePage',
                            params: { id: alaUne.id },
                          }"
                          class="btn style1"
                        >Découvrir
                        </router-link>
                        <!-- <a class="btn style1">Découvrir</a>-->
                        <!-- 
                          <router-link to="{{ alaUne.link }}" class="btn style1"
                          >Découvrir</router-link
                        >
                          <div
                          class="play-video"
                          v-on:click="isPopupMethod(isPopup)"
                          style="cursor: pointer"
                        >
                          <span class="play-btn">
                            <i class="flaticon-play-button-arrowhead"></i>
                          </span>
                          <span class="sm-none">Watch Video</span>
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="hero-img-wrap">
                      <img
                        src="../../assets/images/hero/hero-shape-13.png"
                        alt="Image"
                        class="hero-shape-five bounce"
                      />
                      <img
                        src="../../assets/images/hero/hero-shape-12.png"
                        alt="Image"
                        class="hero-shape-six moveHorizontal"
                      />
                      <img
                        :src="getUrlApiForFiles(alaUne?.urlImage)"
                        alt="Image"
                        class="hero-img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slide>

          <template #addons>
            <Pagination />
          </template>
        </carousel>
      </div>
    </div>

    <div class="popup-video" v-if="isPopup">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="popup-overlay-close" v-on:click="isPopupMethod(isPopup)">
            <div class="popup-overlay-close-line"></div>
            <div class="popup-overlay-close-line"></div>
          </div>
          <div class="play-video">
            <iframe src="https://www.youtube.com/embed/bk7McNUjWgw"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ApiService from "@/services/ApiService";
import { defineComponent, onMounted, ref } from "vue";
import { getUrlApiForFiles } from "../../utils/utils";
import { Carousel, Slide, Pagination } from "vue3-carousel";
import { AlaUne } from "../../models/AlaUne";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "MainBanner",
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  setup: () => {
    const isPopup = ref(false);
    const alaUnes = ref<Array<AlaUne>>([]);
    //const alaUne = ref<AlaUne>();

    const getAllAlaUne = () => {
      return ApiService.get("/alaUne/articles/22")
        .then(({ data }) => {
          console.log("TRTRTRRRTRTRTYUIYTYIUO ===> ",data)
          alaUnes.value = data.data;
          return data.data;
        })
        .catch(({ response }) => {
          //error(response.data.message)
        });
    };

    // const isPopupMethod = (isPopup) => {
    //   return (this.isPopup = !isPopup);
    // };

    onMounted(() => {
      getAllAlaUne();
    });

    return {
      isPopup,
      //alaUne,
      alaUnes,
      getUrlApiForFiles,
      //isPopupMethod()
    };
  },
});
</script>
