<template>
    <div class="faq-wrap  pt-20 pb-20">
        <div class="container">
          <form class="searchbox mb-3" @submit.prevent="rechercher">
        <input
          type="text"
          v-model="searchTerm"
          @keyup="rechercher"
          class="search-input"
          placeholder="Rechercher un FAQ"
        />
        <button type="submit" class="search-button">
          <i class="flaticon-search"></i>
        </button>
      </form>
            <div class="row gx-5 ">
                <div class="col-xl-4 col-lg-12">
                <div class="sidebar">
                    <div class="sidebar-widget style2">
                    <h4>Nos services</h4>
                    <div class="category-box style2">
                        <ul class="list-style">
                            <li v-for="categoriefaq in CategorieFaqs" :key="categoriefaq.id">
                                <a  @click="getFaqByCat(1, 10, '', categoriefaq.id)">
                                    {{ categoriefaq.nom }}
                                    <span><i class="flaticon-next"></i></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    </div>
                </div>
                <div class="sidebar mt-20">
                    <div class="sidebar-widget tags style2">
                        <h4>Tags</h4>
                        <div class="tag-list">
                            <ul class="list-style">
                              <li v-for="faqtag in FaqTags" :key="faqtag.id">
                                  <a  @click="getFaqByTag(faqtag.id)">
                                      {{ faqtag.nom }}
                                  </a>
                              </li>
                            </ul>
                        </div>
                    </div>
                </div>
                </div>
                <div class="col-lg-8">
                    <div class="accordion style2" id="accordionExample">
                        <div class="accordion-item" v-for="faq,index in Faqs" :key="index">
                            <h2 class="accordion-header" :id="`heading${ index }`">
                                <button class="accordion-button collapsed" type="button"
                                    data-bs-toggle="collapse" :data-bs-target="`#collapse${ index }`"
                                    aria-expanded="false" :aria-controls="`collapse${ index }`">
                                    <span>
                                        <i class="ri-arrow-down-s-line plus"></i>
                                        <i class="ri-arrow-up-s-line minus"></i>
                                    </span>
                                    <p v-html="faq.faq ? faq.faq.question : faq.question"></p>
                                </button>
                            </h2>
                            <div :id="`collapse${ index }`" class="accordion-collapse collapse"
                                :aria-labelledby="`heading${ index }`" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div class="single-product-text">
                                        <p v-html="faq.faq ? faq.faq.reponse : faq.reponse"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="">
          <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit" @paginate="handlePaginate" />
      </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent,onMounted,ref } from "vue";
import { Faq } from "../../models/Faq";
import ApiService from "../../services/ApiService";
import axios from "axios";
import { CategorieFaq } from "@/models/CategorieFaq";
import PaginationComponent from '@/components/Utilities/Pagination.vue';

export default defineComponent({
  name: "FaqComp",
  components: {
    PaginationComponent,
  },
  setup() {

    const Faqs = ref<Array<Faq>>([]);   
    const faq = ref<Faq>();

    const CategorieFaqs = ref<Array<CategorieFaq>>([]);
    const FaqTags = ref([]);
    const catId = ref('1');
    const tagId = ref('1');

    // BEGIN PAGINATE
    const searchTerm = ref("");
    const page = ref(1);
    const totalPages = ref(0);
    const limit = ref(10);
    const totalElements = ref(0);

    const handlePaginate = ({ page_: pageNumber, limit_: limitNumber }) => {
      try {
        page.value = pageNumber;
        limit.value = limitNumber;
        getAllFaq(pageNumber, limitNumber, searchTerm.value);
      } catch (error) {
        console.error(error);
      }
    };

    function rechercher() {
      getAllFaq(1, limit.value, searchTerm.value);
  }
    // END PAGINATE

    function getAllCategorieFaq() {
      return ApiService.get('/categorieFaqs')
        .then(response => {
          CategorieFaqs.value = response.data.data.data;
          return response.data.data.data;
        })
        .catch(error => {
          // Gérer l'erreur
        });
    }

    function getAllTag() {
    return ApiService.get('/faqtags')
        .then(response => {
            const uniqueTags = response.data.data.data.reduce((acc, curr) => {
                if (!acc[curr.tag.id]) {
                    acc[curr.tag.id] = curr.tag;
                }
                return acc;
            }, {});
            FaqTags.value = Object.values(uniqueTags);
            return Object.values(uniqueTags);
        })
        .catch(error => {
            // Gérer l'erreur
        });
}

    // function getFaqByCat(catId) {
    //   return ApiService.get(`/categories/faqs/${catId}`)
    function getFaqByCat(page = 1, limi = 10, searchTerm = "", catId) {
      return ApiService.get(
        `categories/faqs/${catId}?page=${page}&limit=${limi}&mot=${searchTerm}&`
      )
        .then(response => {
            Faqs.value = response.data.data.data;
          totalPages.value = response.data.data.totalPages;
          totalElements.value = response.data.data.totalElements;
          console.log('by categories', response.data.data.data)
            return response.data.data.data;
        })
        .catch(error => {
          // Gérer l'erreur
        });
    }

    function getFaqByTag(tagId) {
      console.log('tagId', tagId);
      return ApiService.get(`/tags/faqs/${tagId}`)
        .then(response => {
          Faqs.value = response.data.data.data;
          console.log('by tags', response.data.data)

          return response.data.data;
        })
        .catch(error => {
          // Gérer l'erreur
        });
    }

    // function getAllFaq(page = 1, limi = 10, searchTerm = "") {
    //   return ApiService.get(
    //     `faqs?page=${page}&limit=${limi}&mot=${searchTerm}&`
    //   )
    //     .then((response) => {
    //       Faqs.value = response.data.data.data;
    //       console.log("by all faq", response.data.data.data);
    //       return response.data.data.data;
    //     })
    //     .catch((error) => {
    //       // Gérer l'erreur
    //     });
    // }

    function getAllFaq(page = 1, limi = 10, searchTerm = '') {
      return ApiService.get(`/faqs?page=${page}&limit=${limi}&mot=${searchTerm}&`)
        .then(({ data }) => {
          Faqs.value = data.data.data;
          totalPages.value = data.data.totalPages;
          limit.value = data.data.limit;
          totalElements.value = data.data.totalElements;
          return data.data.data;
        })
        .catch(({ response }) => {
          //error(response.data.message)
      });
    }

    onMounted(() => {
      getAllCategorieFaq()
        .then(() => {
          getFaqByCat(page.value, limit.value, searchTerm.value, catId.value);
          //getFaqByCat(catId.value);
        });
      getAllTag();
      getAllFaq(page.value, limit.value, searchTerm.value);

    });

    return {
      CategorieFaqs,
      FaqTags,
      Faqs,
      getFaqByCat,
      getFaqByTag,
      catId,
      tagId,
      faq,
      searchTerm,
      page,
      totalPages,
      limit,
      totalElements,
      handlePaginate,
      rechercher,
    };
  },
});

</script>

<style>
.searchbox {
  display: flex;
  align-items: center;
  border: 2px solid #ccc;
  border-radius: 25px;
  padding: 5px 10px;
  background-color: #f9f9f9;
  transition: all 0.3s ease;
}

.searchbox:hover {
  border-color: #888;
}

.search-input {
  flex: 1;
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 20px;
  font-size: 16px;
  background-color: transparent;
}

.search-input::placeholder {
  color: #1a1818;
}

.search-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.search-button i {
  font-size: 20px;
  color: #181817;
  transition: color 0.3s ease;
  
}

.search-button:hover i {
  color: #333;
}

</style>