<template>
    <div class="page-wrapper">
        <NavBar class="style2" />
        <MainBanner />
        <ServicesComp />
        <PaymentPlatform />
        <CurrencyComp />
        <ProtectMoney />
        <FinancialPlanning />
        <GlobalFeatures />
        <ContactUs />
        <AppDownload />
        <BlogComp />
        <FooterStyleTwo />
    </div>
</template>

<script>
import NavBar from '../Layouts/NavBar'
import MainBanner from '../HomeTwo/MainBanner'
import ServicesComp from '../HomeTwo/ServicesComp'
import PaymentPlatform from '../HomeTwo/PaymentPlatform'
import CurrencyComp from '../HomeTwo/CurrencyComp'
import ProtectMoney from '../HomeTwo/ProtectMoney'
import FinancialPlanning from '../HomeTwo/FinancialPlanning'
import GlobalFeatures from '../HomeTwo/GlobalFeatures'
import ContactUs from '../HomeTwo/ContactUs'
import AppDownload from '../HomeTwo/AppDownload'
import BlogComp from '../HomeTwo/BlogComp'
import FooterStyleTwo from '../Layouts/FooterStyleTwo'

export default {
    name: 'HomeTwoPage',
    components: {
        NavBar,
        MainBanner,
        ServicesComp,
        PaymentPlatform,
        CurrencyComp,
        ProtectMoney,
        FinancialPlanning,
        GlobalFeatures,
        ContactUs,
        AppDownload,
        BlogComp,
        FooterStyleTwo,
    }
}
</script>