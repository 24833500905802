<template>
  <div class="faq-wrap ptb-100">
    <div class="container">
      <form class="searchbox mb-3" @submit.prevent="rechercher">
        <input
          type="text"
          v-model="searchTerm"
          @keyup="rechercher"
          class="search-input"
          placeholder="Rechercher un FAQ"
        />
        <button type="submit" class="search-button">
          <i class="flaticon-search"></i>
        </button>
      </form>

      <div class="row gx-5">
        <div class="col-xl-4 col-lg-12">
          <div class="sidebar">
            <div class="sidebar-widget tags style2">
              <h4>Tags</h4>
              <div class="tag-list">
                <ul class="list-style">
                  <li v-for="tag in faqTags" :key="tag.id">
                    <a  @click.prevent="getFaqByTag(tag.tagId)">{{
                      tag.tag.nom
                    }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-8">
          <div class="accordion style2" id="accordionExample">
            <div
              class="accordion-item"
              v-for="(faq, index) in Faqs"
              :key="index"
            >
              <h2 class="accordion-header" :id="`heading${index}`">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="`#collapse${index}`"
                  aria-expanded="false"
                  :aria-controls="`collapse${index}`"
                >
                  <span>
                    <i class="ri-arrow-down-s-line plus"></i>
                    <i class="ri-arrow-up-s-line minus"></i>
                  </span>
                  <p v-html="faq.faq ? faq.faq.question : faq.question"></p>
                </button>
              </h2>
              <div
                :id="`collapse${index}`"
                class="accordion-collapse collapse"
                :aria-labelledby="`heading${index}`"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div class="single-product-text">
                    <p v-html="faq.faq ? faq.faq.reponse : faq.reponse"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="">
        <PaginationComponent
          :page="page"
          :totalPages="totalPages"
          :totalElements="totalElements"
          :limit="limit"
          @paginate="handlePaginate"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { Faq } from "../../models/Faq";
import ApiService from "../../services/ApiService";
import PaginationComponent from "@/components/Utilities/Pagination.vue";

export default defineComponent({
  name: "FaqServiceComp",
  components: {
    PaginationComponent,
  },
  setup() {
    const Faqs = ref<Array<Faq>>([]);
    const faqTags = ref([]);

    const catId = ref("3");

    // BEGIN PAGINATE
    const searchTerm = ref("");
    const page = ref(1);
    const totalPages = ref(0);
    const limit = ref(10);
    const totalElements = ref(0);

    const handlePaginate = ({ page_: pageNumber, limit_: limitNumber }) => {
      try {
        page.value = pageNumber;
        limit.value = limitNumber;
        getAllFaqByCategorie(pageNumber, limitNumber, searchTerm.value, catId.value);
      } catch (error) {
        console.error(error);
      }
    };

    function rechercher() {
    getAllFaqByCategorie(1, limit.value, searchTerm.value, catId.value);
  }

  function getAllFaqByCategorie(page = 1, limit = 10, searchTerm = "", catId) {
  // if (typeof catId !== 'string' && typeof catId !== 'number') {
  //   throw new Error('catId should be a string or number');
  // }

  return ApiService.get(`faqs/${catId}?page=${page}&limit=${limit}&mot=${searchTerm}`)
    .then((response) => {
      const responseData = response.data.data;
      Faqs.value = responseData.data;
      totalPages.value = responseData.totalPages;
      totalElements.value = responseData.totalElements;
      faqTags.value = [];

      const uniqueTags = {};

      Faqs.value.forEach((faq) => {
        if (faq.faqtags) {
          faq.faqtags.forEach((tag) => {
            if (!uniqueTags[tag.tagId]) {
              uniqueTags[tag.tagId] = tag;
            }
          });
        }
      });

      faqTags.value = Object.values(uniqueTags);
      console.log("faqTags", faqTags.value);
      console.log("Faqs", Faqs.value);
      console.log("catId", catId);
      return Faqs.value;
    })
    .catch((error) => {
      console.error("Error fetching FAQs:", error);
    });
}


    function getFaqByTag(tagId) {
      console.log("tagId", tagId);
      return ApiService.get(`/tagforfaqs/${tagId}`)
        .then((response) => {
          Faqs.value = response.data.data;
          return Faqs.value;
        })
        .catch(({ response }) => {
          console.error("Error fetching FAQs by tag:", response);
        });
    }

    onMounted(() => {
      getAllFaqByCategorie(page.value, limit.value, searchTerm.value, catId.value);
    });

    return {
      Faqs,
      faqTags,
      getFaqByTag,
      searchTerm,
      page,
      totalPages,
      limit,
      totalElements,
      handlePaginate,
      rechercher,
    };
  },
});
</script>


<style>
.searchbox {
  display: flex;
  align-items: center;
  border: 2px solid #ccc;
  border-radius: 25px;
  padding: 5px 10px;
  background-color: #f9f9f9;
  transition: all 0.3s ease;
}

.searchbox:hover {
  border-color: #888;
}

.search-input {
  flex: 1;
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 20px;
  font-size: 16px;
  background-color: transparent;
}

.search-input::placeholder {
  color: #1a1818;
}

.search-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.search-button i {
  font-size: 20px;
  color: #181817;
  transition: color 0.3s ease;
  
}

.search-button:hover i {
  color: #333;
}

</style>