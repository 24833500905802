import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "service-wrap pt-20 pb-20" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "blog-card style1" }
const _hoisted_5 = ["href"]
const _hoisted_6 = {
  class: "blog-img",
  style: {"height":"277px"}
}
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.articles, (article, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "col-xl-4 col-lg-6 col-md-6",
            key: index
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("a", {
                href: _ctx.getUrlApiForFiles(article.type),
                "data-lightbox": "gallery"
              }, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("img", {
                    src: _ctx.getUrlApiForFiles(article.type),
                    alt: "Image",
                    style: {"width":"100%","height":"auto","object-fit":"contain"}
                  }, null, 8, _hoisted_7)
                ])
              ], 8, _hoisted_5)
            ])
          ]))
        }), 128))
      ])
    ])
  ]))
}