<template>
      <div class="container">
          <div v-for="apropos in aproposs" :key="apropos.id">
          <p class="mb-20" v-html="apropos.cookie"></p>
          </div>
      </div>
</template>


<script lang="ts">
import ApiService from "@/services/ApiService";
import { defineComponent, onMounted, ref} from "vue";
import { Apropos } from "../../models/Apropos";
import { error } from "../../utils/utils";
import axios from "axios";

export default defineComponent({
  name: "CooKie",
  components: {},
  setup() {

        const aproposs = ref<Array<Apropos>>([]);

        function getActifApropos() {
            return ApiService.get('/apropos/statut/1')
            .then(response => {
                aproposs.value = response.data.data;
                return response.data.data;
            })
            .catch(({ response }) => {
                error(response.data.message)
            });
        }
     
        onMounted(() => {
        getActifApropos();
        });


        return {
            aproposs,
            getActifApropos
        };
    }
});
</script>