<template>
    <div class="page-wrapper">
        <NavBar class="style1" />
        <div class="content-wrapper">
            <PageTitle pageTitle="Blog Details Left Sidebar" />
            <BlogDetailsLeftSidebar />
        </div>
        <FooterComp />
    </div>
</template>

<script>
import NavBar from '../Layouts/NavBar'
import PageTitle from '../Common/PageTitle'
import BlogDetailsLeftSidebar from '../BlogDetailsLeftSidebar/BlogDetailsLeftSidebar'
import FooterComp from '../Layouts/FooterComp'

export default {
    name: 'BlogDetailsLeftSidebarPage',
    components: {
        NavBar,
        PageTitle,
        BlogDetailsLeftSidebar,
        FooterComp,
    }
}
</script>