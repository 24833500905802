import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "service-wrap pt-20 pb-20" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row justify-content-center" }
const _hoisted_4 = { class: "blog-card style1" }
const _hoisted_5 = {
  class: "blog-img",
  style: {"height":"277px"}
}
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "blog-info" }
const _hoisted_8 = { class: "text-black" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.articles, (article, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "col-xl-4 col-lg-6 col-md-6",
            key: index
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("img", {
                  src: _ctx.getUrlApiForFiles(article.urlImage),
                  alt: "Image",
                  style: {"width":"100%","height":"auto","object-fit":"contain"}
                }, null, 8, _hoisted_6)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("h3", null, [
                  _createVNode(_component_router_link, {
                    to: { name: 'ViewMediathequePage', params: { id: article.id } }
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(article.titre), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ]),
                _createVNode(_component_router_link, {
                  to: { name: 'ViewMediathequePage', params: { id: article.id } },
                  class: "link style text-center",
                  style: {"text-align":"center"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Afficher "),
                    _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.totalMedias[index]), 1),
                    _createTextVNode(" image(s) ")
                  ]),
                  _: 2
                }, 1032, ["to"])
              ])
            ])
          ]))
        }), 128))
      ])
    ])
  ]))
}