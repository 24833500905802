<template>
    <div class="service-wrap ptb-100">
        <div class="container">

            <div class="row ">
                
            </div> 
            
        </div>
    </div>
</template>

<script lang="ts">

import ApiService from "@/services/ApiService";
import { getUrlApiForFiles, error,format_date } from "@/utils/utils";
import { defineComponent, ref, onMounted } from "vue";

export default defineComponent({
    name: 'ProduitTransportLocation',
    components: {
  },
  setup(){

    return { 
    };
  },
});
</script>