<template>
    <section class="why-choose-wrap style1 pt-100 pb-100 bg-bunting">
        <div class="container">
            <div class="row gx-5 align-items-center">
                <div class="col-lg-6">
                    <div class="">
                        <img src="../../assets/images/why-choose-us/wh-img-1.png" style="width: 82%;padding-top: 10%;" alt="Image">
                        <img class="wh-shape-one bounce" src="../../assets/images/why-choose-us/wh-shape-1.png" alt="Image">
                        <img class="wh-shape-two animationFramesTwo" src="../../assets/images/why-choose-us/wh-shape-2.png" alt="Image">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="wh-content">
                        <div class="content-title style1">
                            <span>Pourquoi nous choisir ?</span>
                            <h2 style="text-transform: uppercase !important;" >Bénéficiez du service de livraison, de boîte postale et de prêt le plus rapide</h2>
                        </div>
                        <div class="feature-item-wrap">
                            <div class="feature-item">
                                <span class="feature-icon">
                                    <i class="flaticon-graph"></i>
                                </span>
                                <div class="feature-text">
                                    <h3>Livraison rapide</h3>
                                    <p>Pour tous vos achats en ligne, la Poste reste disponible pour les services de livraison.</p>
                                </div>
                            </div>
                            <div class="feature-item">
                                <span class="feature-icon">
                                    <i class="flaticon-loan-1"></i>
                                </span>
                                <div class="feature-text">
                                    <h3>Boîte postale</h3>
                                    <p>Les boîtes postales vous permettront d’envoyer et de recevoir vos courriers ou colis.</p>
                                </div>
                            </div>
                            <div class="feature-item">
                                <span class="feature-icon">
                                    <i class="flaticon-computer"></i>
                                </span>
                                <div class="feature-text">
                                    <h3>Prêt</h3>
                                    <p>Solliciter un prêt en cas de besoins pourrait être une option pratique pour des solutions de financement accessibles et fiables.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'WhyChooseUs'
}
</script>