import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "service-wrap ptb-100" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row justify-content-left" }
const _hoisted_4 = { class: "col-md-3" }
const _hoisted_5 = { class: "col-md-9" }
const _hoisted_6 = { class: "row justify-content-left" }
const _hoisted_7 = { class: "feature-card style3" }
const _hoisted_8 = { class: "feature-info" }
const _hoisted_9 = { class: "feature-title" }
const _hoisted_10 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuProduit = _resolveComponent("MenuProduit")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_MenuProduit)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.articles, (article, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "col-xl-4 col-lg-4 col-md-4",
                key: index
              }, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("span", null, [
                        _createElementVNode("img", {
                          src: _ctx.getUrlApiForFiles(article.urlImage),
                          alt: "Image"
                        }, null, 8, _hoisted_10)
                      ]),
                      _createElementVNode("h3", null, [
                        _createVNode(_component_router_link, {
                          to: { name: 'ViewArticlePage', params: { id: article.id } }
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(article.titre), 1)
                          ]),
                          _: 2
                        }, 1032, ["to"])
                      ])
                    ]),
                    _createElementVNode("p", null, [
                      _createVNode(_component_router_link, {
                        to: { name: 'ViewArticlePage', params: { id: article.id } }
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(article.sousTitre), 1)
                        ]),
                        _: 2
                      }, 1032, ["to"])
                    ])
                  ])
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}