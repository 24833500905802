<template>
    <div class="page-wrapper">
        <NavBar class="style1" />
        <div class="content-wrapper">
            <PageTitle pageTitle="Apply Loan" />
            <ApplyComp />
        </div>
        <FooterComp />
    </div>
</template>

<script>
import NavBar from '../Layouts/NavBar'
import PageTitle from '../Common/PageTitle'
import ApplyComp from '../Apply/ApplyComp'
import FooterComp from '../Layouts/FooterComp'

export default {
    name: 'ApplyPage',
    components: {
        NavBar,
        PageTitle,
        ApplyComp,
        FooterComp,
    }
}
</script>