<template>
    <div class="blog-details-wrap pt-20 pb-20">
        <div class="container">
            <div class="row gx-5">
                <div class="col-xl-10 offset-xl-1 col-lg-10 offset-lg-1">
                    <div>
                        <div class="post-img">
                            <img src="../../assets/images/blog/single-blog-1.jpg" alt="Image">
                        </div>
                        <ul class="post-metainfo  list-style">
                            <li><i class="flaticon-user"></i><router-link to="/blog">Admin</router-link></li>
                            <li><i class="flaticon-calendar"></i>01 Nov, 2021</li>
                            <li><i class="flaticon-bubble-chat"></i>No Comment</li>
                        </ul>
                        <h2>5 Things To Know About Your Online Banking</h2>
                        <div class="post-para">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt labore et dolore sitor magna aliqua. Quis ipsum suspendisse ultrices <strong>gravida</strong>. Risus commodo viverra manas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            <p>No sea takimata sanctus est Lorem <router-link to="/">Ipsum</router-link> dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et.</p>
                            <blockquote class="wp-block-quote">
                                <span class="wp-quote-icon"><i class="flaticon-quotation-mark"></i></span>
                                <div class="wp-quote-text">
                                    <p>"Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed cum fuga ab, eum numquam ea dolores quae takimata sanctus est Lorem amet ducimus Lorem ipsum, dolor sit amet consectetur adipisicing amet dolor"</p>
                                    <h6>Mal Practive</h6>
                                </div>
                            </blockquote>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt labore et dolore sitor magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra manas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            <h3>Principles Related To Human Capital</h3>
                            <ol>
                                <li>Mauris ut in vestibulum hasellus ultrices fusce nibh justo, venenatis, amet. Lectus quam.</li>
                                <li>Consectetur phasellus <strong>ultrices</strong> fusce nibh justo, venenatis, amet. Lectus quam.</li>
                                <li>Lectus quam there are two thing is very important in Consectetur phasellus ultrices fusce.</li>
                            </ol>
                            <h3>How Do Credit Cards Work</h3>
                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam in culpa dolorem. Eos sint aut suscipit.</p>
                            <ul class="content-feature-list style2 list-style mt-15">
                                <li>
                                    <i class="flaticon-checkbox"></i>
                                    Lorem ipsum dolor, sit amet.
                                </li>
                                <li>
                                    <i class="flaticon-checkbox"></i>
                                    Amet consectetur adipisicing elit Officia.
                                </li>
                                <li>
                                    <i class="flaticon-checkbox"></i>
                                    Aquaerat ipsa quis possimus.
                                </li>
                            </ul>
                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua at vero amet dolor sit consect. </p>
                                
                            <p>Lorem ipsum dolor sit amet, consectetur <strong>adipisicing</strong> elit, sed do eiusmod tempor quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in <router-link to="/">sed</router-link> uia non numquam eius modi tempora incidunt ut labore dolor.</p>
                        </div>
                    </div>
                    <div class="post-meta-option">
                        <div class="row gx-0 align-items-center">
                            <div class="col-md-7 col-12">
                                <div class="post-tag">
                                    <span> <i class="flaticon-supermarket"></i>Tags:</span>
                                    <ul class="tag-list list-style">
                                        <li><router-link to="/blog">Loan</router-link>,</li>
                                        <li><router-link to="/blog">Banking</router-link>,</li>
                                        <li><router-link to="/blog">Card</router-link></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-5 col-12 text-md-end text-start">
                                <div class="post-share w-100">
                                    <span>Share:</span>
                                    <ul class="social-profile style2 list-style">
                                        <li>
                                            <a target="_blank" href="https://facebook.com">
                                                <i class="ri-facebook-fill"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a target="_blank" href="https://twitter.com">
                                                <i class="ri-twitter-fill"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a target="_blank" href="https://linkedin.com">
                                                <i class="ri-linkedin-fill"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a target="_blank" href="https://instagram.com">
                                                <i class="ri-pinterest-fill"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="post-author">
                        <div class="post-author-img">
                            <img src="../../assets/images/blog/author-1.jpg" alt="Image">
                        </div>
                        <div class="post-author-info">
                            <h4>Fransis Josef</h4>
                            <p>Claritas est etiam amet sinicus, qui sequitur lorem ipsum semet coui lectorum. Lorem ipsum dolor voluptatem corporis blanditiis sadipscing elitr sed diam nonumy eirmod amet sit lorem dolor.</p>
                            <ul class="social-profile style2 list-style">
                                <li>
                                    <a target="_blank" href="https://facebook.com">
                                        <i class="ri-facebook-fill"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://twitter.com">
                                        <i class="ri-twitter-fill"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://linkedin.com">
                                        <i class="ri-linkedin-fill"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="comment-box-wrap">
                        <div class="comment-box-title mb-30">
                            <h4><span>3</span> Comments</h4>
                        </div>
                        <div class="comment-item-wrap">
                            <div class="comment-item">
                                <div class="comment-author-img">
                                    <img src="../../assets/images/blog/author-2.jpg" alt="mage">
                                </div>
                                <div class="comment-author-wrap">
                                    <div class="comment-author-info">
                                        <div class="row align-items-start">
                                            <div class="col-md-9 col-sm-12 col-12 order-md-1 order-sm-1 order-1">
                                                <div class="comment-author-name">
                                                    <h5>Olivic Dsuza <span class="comment-date">Oct 22, 2021</span></h5>
                                                </div>
                                            </div>
                                            <div class="col-md-3 col-sm-12 col-12 text-md-end order-md-2 order-sm-3 order-3">
                                                <a href="#cmt-form" class="reply-btn">Reply</a>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12 order-md-3 order-sm-2 order-2">
                                                <div class="comment-text">
                                                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                                                    sed diam nonumy eirmod  tempor invidunt ut labore et dolore
                                                    magna aliquyam erat.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="comment-item reply">
                                <div class="comment-author-img">
                                    <img src="../../assets/images/blog/author-3.jpg" alt="mage">
                                </div>
                                <div class="comment-author-wrap">
                                    <div class="comment-author-info">
                                        <div class="row align-items-start">
                                            <div class="col-md-9 col-sm-12 col-12 order-md-1 order-sm-1 order-1">
                                                <div class="comment-author-name">
                                                    <h5>Everly Leah <span class="comment-date">Oct 30, 2021</span></h5>
                                                </div>
                                            </div>
                                            <div class="col-md-3 col-sm-12 col-12 text-md-end order-md-2 order-sm-3 order-3">
                                                <a href="#cmt-form" class="reply-btn">Reply</a>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12 order-md-3 order-sm-2 order-2">
                                                <div class="comment-text">
                                                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                                                    sed diam nonumy eirmod  tempor invidunt ut labore et dolore
                                                    magna aliquyam erat.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="comment-item">
                                <div class="comment-author-img">
                                    <img src="../../assets/images/blog/author-4.jpg" alt="mage">
                                </div>
                                <div class="comment-author-wrap">
                                    <div class="comment-author-info">
                                        <div class="row align-items-start">
                                            <div class="col-md-9 col-sm-12 col-12 order-md-1 order-sm-1 order-1">
                                                <div class="comment-author-name">
                                                    <h5>Ella Jackson <span class="comment-date">Sep 15, 2021</span></h5>
                                                </div>
                                            </div>
                                            <div class="col-md-3 col-sm-12 col-12 text-md-end order-md-2 order-sm-3 order-3">
                                                <a href="#cmt-form" class="reply-btn">Reply</a>
                                            </div>
                                            <div class="col-md-12 col-sm-12 col-12 order-md-3 order-sm-2 order-2">
                                                <div class="comment-text">
                                                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                                                    sed diam nonumy eirmod  tempor invidunt ut labore et dolore
                                                    magna aliquyam erat.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="cmt-form">
                        <div class="comment-box-title mb-25">
                            <h4>Leave A Comment</h4>
                            <p>Your email address will not be published. Required fields are marked.</p>
                        </div>
                        <form @submit.prevent class="comment-form">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" required placeholder="Name*">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" required placeholder="Email Address*">
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <textarea name="messages" id="messages" cols="30" rows="10" placeholder="Please Enter Your Comment Here"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-md-12">
                                    <div class="checkbox style2">
                                        <input type="checkbox" id="test_1">
                                        <label for="test_1">Save my name,email,website addres in this browser for the next time I commnet.</label>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-20">
                                    <button class="btn style1">Post A Comment</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'BlogDetails'
});
</script>