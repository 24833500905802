<template>
    <div class="page-wrapper">
        <NavBar class="style1" />
        <div class="content-wrapper">
            <PageTitle pageTitle="My Account" />
            <MyAccount />
        </div>
        <FooterComp />
    </div>
</template>

<script>
import NavBar from '../Layouts/NavBar'
import PageTitle from '../Common/PageTitle'
import MyAccount from '../MyAccount/MyAccount'
import FooterComp from '../Layouts/FooterComp'

export default {
    name: 'MyAccountPage',
    components: {
        NavBar,
        PageTitle,
        MyAccount,
        FooterComp,
    }
}
</script>