<template>
    <div class="page-wrapper">
        <NavBar class="style1" />
        <MainBanner />
        <OnlineShopping />
        <FeatureComp />
        <ChargeCalculator />
        <WhyChooseUs />
        <CounterComp />
        <ServicesPoste />
        <!-- <Testimonials />
        <AppDownload /> -->
        <!-- <GoalSettings /> -->
        <BlogComp />
        <FooterComp />
    </div>
    
    
</template>

<script>
import NavBar from '../Layouts/NavBar'
import MainBanner from '../HomeOne/MainBanner'
import ChargeCalculator from '../HomeOne/ChargeCalculator'
import FeatureComp from '../HomeOne/FeatureComp'
import WhyChooseUs from '../HomeOne/WhyChooseUs'
import CounterComp from '../HomeOne/CounterComp'
import ServicesPoste from '../HomeOne/ServicesPoste'
import OnlineShopping from '../HomeOne/OnlineShopping'
// import TestimonialsComp from '../HomeOne/TestimonialsComp'
// import AppDownload from '../HomeOne/AppDownload'
//import GoalSettings from '../HomeOne/GoalSettings'
import BlogComp from '../HomeOne/BlogComp'
import FooterComp from '../Layouts/FooterComp'

export default {
    name: 'HomeOnePage',
    components: {
        NavBar,
        MainBanner,
        ChargeCalculator,
        FeatureComp,
        WhyChooseUs,
        CounterComp,
        ServicesPoste,
        OnlineShopping,
        //TestimonialsComp,
        //AppDownload,
        //GoalSettings,
        BlogComp,
        FooterComp,
    }
}
</script>