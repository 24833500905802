import type { App } from "vue";
import type { AxiosResponse } from "axios";
import axios from "axios";
import VueAxios from "vue-axios";

class ApiService {
  public static vueInstance: App;
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL ='https://137.255.12.34:3005/api';
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] ="application/json";
    ApiService.vueInstance.axios.defaults.headers.common["Content-Type"] = "application/json";
    ApiService.vueInstance.use(VueAxios, axios);
  }
   
  
  public static setHeader(): void {
    // ApiService.vueInstance.axios.defaults.headers.common["Authorization"] = `bearer ${JwtService.getToken()}`;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] ="application/json";
    console.log(ApiService.vueInstance.axios.defaults.headers,'ApiService.vueInstance.axios.defaults.headers');
  }

  public static query(resource: string, params: any): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, { params: params });
    //  return ApiService.vueInstance.axios.get(resource, params);
  }

  public static get(resource: string,slug = "" as string ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${resource}/${slug}`);
  }

  public static post(resource: string, params: any): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  public static update(resource: string,slug: string,params: any): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  public static put(resource: string, params: any): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource);
  }

}

export default ApiService;
