<template>
    <div class="page-wrapper">
        <NavBar class="style1" />
        <div class="content-wrapper">
            <PageTitle pageTitle="Données personnelles" />
            <DonnePersonnelle />
        </div>
        <FooterComp />
    </div>
</template>

<script>
import NavBar from '../Layouts/NavBar'
import PageTitle from '../Common/PageTitle'
import DonnePersonnelle from '../MentionLegale/DonnePersonnelle'
import FooterComp from '../Layouts/FooterComp'

export default {
    name: 'DonnePersonnellePage',
    components: {
        NavBar,
        PageTitle,
        DonnePersonnelle,
        FooterComp,
    }
}
</script>