<template>
    <div class="terms-wrap pt-100 pb-100">
        <div class="container">
            <div class="row gx-5">
                <div class="col-lg-12">
                    <div class="single-terms">
                        <h3>Information Collection</h3>
                        <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime nulla minus quasi. Voluptatem, <router-link to="/">company name</router-link> saepe ullam autem magni quod sint tempore, eius molestias aliquam debitis. Neque saepe dignissimos repudiandae fuga.
                        </p>
                        <p>
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nihil eveniet quas dignissimos <strong>activities</strong> ea pariatur corrupti rerum deserunt, ipsum, ipsa eos veniam aspernatur fuga, optio soluta? Libero neque reiciendis cupiditate dolores nam. Earum eius similique sapiente. Iure, sit non. At fuga ipsam veniam.
                        </p>
                    </div>
                    <div class="single-terms">
                        <h3>How We Use Cookies</h3>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nihil eveniet quas dignissimos doloribus ea pariatur corrupti rerum deserunt, ipsum, ipsa eos veniam aspernatur fuga, optio soluta? Libero neque reiciendis cupiditate dolores nam. Earum eius similique sapiente. Iure, sit non. At fuga ipsam veniam.</p>
                        <ul class="content-feature-list list-style mb-0">
                            <li><i class="ri-checkbox-multiple-line"></i>Lorem ipsum dolor, sit amet.</li>
                            <li><i class="ri-checkbox-multiple-line"></i>Amet consectetur adipisicing elit.
                                Officia, odit!</li>
                            <li><i class="ri-checkbox-multiple-line"></i>Aquaerat ipsa quis possimus.</li>
                            <li><i class="ri-checkbox-multiple-line"></i>Lorem, ipsum dolor sit amet
                                consectetur adipi.</li>
                            <li><i class="ri-checkbox-multiple-line"></i>Consectetur adipisicing elit.
                                Voluptatibus, dignissimos.</li>
                            <li><i class="ri-checkbox-multiple-line"></i>Highly professional administration.
                            </li>
                        </ul>
                    </div>
                    <div class="single-terms">
                        <h3>The Collection, Process, and Use of Personal Data</h3>
                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime nulla minus quasi. Voluptatem, facilis saepe ullam autem magni quod sint tempore, eius molestias aliquam debitis. Neque saepe dignissimos repudiandae fuga.</p>
                    </div>
                    <div class="single-terms">
                        <h3>Data Protection</h3>
                        <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime nulla minus quasi. Voluptatem, company name saepe ullam autem magni quod sint tempore, eius molestias aliquam debitis. Neque saepe dignissimos repudiandae fuga.
                        </p>
                        <p>
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nihil eveniet quas dignissimos <strong>aspernatur</strong> ea pariatur corrupti rerum deserunt, ipsum, ipsa eos veniam aspernatur fuga, optio soluta? Libero neque reiciendis cupiditate dolores nam. Earum eius similique sapiente. Iure, sit non. At fuga ipsam veniam.
                        </p>
                    </div>
                    <div class="single-terms">
                        <h3>The Collection, Process and Use of Personal Data</h3>
                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Harum, quod. Ratione ex delectus quis tenetur odio non alias numquam official ipsum dolor sit, amet consectetur adipisicing elit. Accusamus, laborum.</p>
                        <ol>
                            <li>Mauris ut in vestibulum hasellus ultrices fusce nibh justo, venenatis, amet. Lectus quam in lobortis.</li>
                            <li>Consectetur phasellus <strong>ultrices</strong> fusce nibh justo, venenatis, amet. Lectus quam in lobortis justo venenatis amet.</li>
                            <li>Lectus quam there are two thing is very important in Consectetur phasellus ultrices fusce nibh justo, venenatis, amet in lobortis.</li>
                            <li>Web Development very creative to do something , mauris ut in vestibulum.</li>
                        </ol>
                    </div>
                    <div class="single-terms">
                        <h3>Our Policy For Age Under 18</h3>
                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime nulla minus quasi. Voluptatem, facilis saepe ullam autem magni quod sint tempore, eius molestias aliquam debitis. Neque saepe dignissimos repudiandae fuga.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PrivacyPolicy'
}
</script>