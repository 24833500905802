<template>
    <div class="error-wrapper">
        <div class="error-wrap">
            <div class="error">
                <div class="container">
                    <div class="error-content">
                        <img src="../../assets/images/404.png" alt="Iamge">
                        <h2>Oops! Erreur de page</h2>
                        <p>La page que vous recherchez a peut-être été supprimée, son nom a changé ou est temporairement indisponible.</p>
                        <router-link to="/" class="btn style1">Retour</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ErrorPage'
}
</script>