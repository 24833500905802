<template>
    <div class="contact-wrap  pt-20 pb-20">
        <div class="container">
            <div class="row justify-content-center pb-75">
                <div class="col-lg-4 col-md-6" v-for="groupedParametre in groupedParametres" :key="groupedParametre[0].id">
                    <div class="contact-item">
                        <span class="contact-icon" v-if="groupedParametre[0].categorieInfo.libelle == 'Email'">
                            <i class="flaticon-email-2"></i>
                        </span>
                        <span class="contact-icon" v-if="groupedParametre[0].categorieInfo.libelle == 'Adresse'">
                            <i class="flaticon-map"></i>
                        </span>
                        <span class="contact-icon" v-if="groupedParametre[0].categorieInfo.libelle == 'Téléphone'">
                            <i class="flaticon-phone-call"></i>
                        </span>
                        <div class="contact-info">
                            <h3>{{ groupedParametre[0].categorieInfo.libelle }}</h3>
                            <div v-for="parametre in groupedParametre" :key="parametre.id">
                                <p v-if="parametre.categorieInfo.libelle === 'Adresse'">{{ parametre.libelle }}</p>
                                <a v-if="parametre.categorieInfo.libelle === 'Téléphone'" :href="'tel:' + parametre.libelle">{{ parametre.libelle }}</a>
                                <a v-if="parametre.categorieInfo.libelle === 'Email'" :href="'mailto:' + parametre.libelle">{{ parametre.libelle }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="comp-map pb-100">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8385385572983!2d144.95358331584498!3d-37.81725074201705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d4dd5a05d97%3A0x3e64f855a564844d!2s121%20King%20St%2C%20Melbourne%20VIC%203000%2C%20Australia!5e0!3m2!1sen!2sbd!4v1612419490850!5m2!1sen!2sbd"></iframe>
            </div>
        </div>
        <div class="contact-form-area ptb-100 bg-albastor">
            <img src="../../assets/images/contact-shape-1.png" alt="Image" class="contact-shape-one animationFramesTwo">
            <img src="../../assets/images/contact-shape-2.png" alt="Image" class="contact-shape-two bounce">
            <div class="container">
                <div class="row">
                    <div class="col-xl-10 offset-xl-1 col-lg-10 offset-lg-1">
                        <div class="content-title style1 text-center mb-40">
                            <span>Envoyez nous un message</span>
                            <h2>Avez-vous une question?</h2>
                        </div>
                        <div class="account-form" >
                            <Form @submit="addContact" class="form-wrap" :validation-schema="contactSchema" style="border: 20px">
                                    <div class="row">
                                        <div class="col-md-6" >
                                                <div class="form-group">
                                                    <!-- <label for="fname">Nom</label> -->
                                                    <Field name="nomComplet" type="text" placeholder="Votre nom*" id="name" className="input-field" 
                                                />
                                                </div>
                                                <ErrorMessage name="nomComplet" class="text-danger"/>

                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <!-- <label for="email">Email</label> -->
                                                <Field name="email" type="text" placeholder="Votre email*" id="email"
                                               />
                                            <ErrorMessage name="email" class="text-danger"/>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <!-- <label for="phone">Telephone</label> -->
                                                <Field name="telephone" type="number" placeholder="Votre numéro de téléphone" id="phone" class="intex"/>
                                                <ErrorMessage name="telephone" class="text-danger"/>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <Field  name="service"  v-slot="{ field }" v-model="serviceD" class="intex">
                                                <select
                                                v-model = "field.value"
                                                v-bind = "field">
                                                <option selected="selected" disabled>Choissisez le service</option>
                                                <option v-for="option in serviceOptions" :key="option.value" :value="option.value">{{ option.label }}</option>
                                            </select>
                                            </Field>
                                            <ErrorMessage name="service" class="text-danger"/>

                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <!-- <label for="address">Address 1</label> -->
                                                <Field name="message" as="textarea"  cols="30" class="intex"
                                                    rows="30" placeholder="Laissez votre message.." v-slot="{ field}" >
                                                <textarea id="message" class="txt"
                                                    v-model="field.value"
                                                ></textarea>
                                                </Field>
                                                <ErrorMessage name="message" class="text-danger"/>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <button class="btn style1 w-100 d-block"> Envoyer</button>
                                        </div>
                                    </div>
                                </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import ApiService from "@/services/ApiService";
import { defineComponent, onMounted, ref, computed} from "vue";
import { error, success } from "../../utils/utils";
import { Parametre } from "@/models/Parametre";
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import { Contact } from "@/models/Contact";

export default {
    name: 'ContactCompo',
    components: {
        Field,
        Form,
        ErrorMessage
    },
    setup() {
        const contactSchema = Yup.object().shape({
        email: Yup.string().email('Veuillez entrer un email valide').required('L\'email est obligatoire'),
        telephone: Yup.string().required('Le télephone est obligatoire'),
        nomComplet: Yup.string().required('Le nom est obligatoire'),
        service: Yup.string().required('Le service est obligatoire'),
        message: Yup.string().required('Le message est obligatoire'),
        });
        
        const Parametres = ref<Array<Parametre>>([]);
        const parametre = ref<Parametre>();
        const contactForm = ref<Contact | null>(null);
        
        const groupedParametres = computed(() => {
            const grouped = {};
            Parametres.value.forEach(parametre => {
                if (parametre.categorieInfo.libelle in grouped) {
                    grouped[parametre.categorieInfo.libelle].push(parametre);
                } else {
                    grouped[parametre.categorieInfo.libelle] = [parametre];
                }
            });
            return Object.values(grouped);
        });

        function getContacts() {
            return ApiService.get('/parametres')
                .then(response => {
                    Parametres.value = response.data.data.data;
                    return response.data.data.data;
                })
                .catch(error => {
                    // Gérer l'erreur
                });
        }

        const serviceOptions = ref([]);
        const serviceD = ref("Choissisez le service")
        const fetchCategorie = async () => {
            try {
                const response = await ApiService.get('/categorieArticleChilds');
                const categorieArticleIsService = response.data.data.filter(category => category.isService == "Oui");
                serviceOptions.value = categorieArticleIsService.map((categorie) => ({
                    value: categorie.id,
                    label: `${categorie.nom}`,
                }));
                console.log('services options', serviceOptions.value)
            } catch (error) {
                // Gérer l'erreur
            }
        }

        const addContact = async (values, {resetForm}) => {
        console.log('okfefep', values)
        if(values['service'] !== 'Choissisez le service') {
            ApiService.post("/contacts",values)
                .then(({ data }) => {
                if(data.code == 201) { 
                    success(data.message);
                    resetForm();
                }
                }).catch(({ response }) => {
                error(response.data.message);
                });
            }
        };

        
    onMounted(() => {
        getContacts();
        fetchCategorie();
    });
    
    return {
        Parametres,
        parametre,
        getContacts,
        groupedParametres,
        addContact,
        contactSchema,
        contactForm,
        serviceOptions,
        serviceD
            
    };

    }
}
</script>

<style >

.form-wrap .form-group input:focus,
.form-wrap .form-group select:focus,
.form-wrap .form-group textarea:focus {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.form-wrap .form-group select {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
</style>