<template>
    <div class="service-wrap ptb-100">
        <div class="container">
            <div class="row justify-content-left">
                <div class="col-md-3">
                    <MenuProduit />
                </div>
                <div class="col-md-9">
                    <div class="row justify-content-left">
                        <div class="col-xl-4 col-lg-4 col-md-4" v-for="(article, index) in articles" :key="index">
                            <div class="feature-card style3">
                                <div class="feature-info">
                                    <div class="feature-title">
                                        <span><img :src="getUrlApiForFiles(article.urlImage)" alt="Image"></span>
                                        <h3><router-link
                                                :to="{ name: 'ViewArticlePage', params: { id: article.id } }">{{
                            article.titre }}</router-link></h3>
                                    </div>
                                    <p><router-link :to="{ name: 'ViewArticlePage', params: { id: article.id } }">{{
                            article.sousTitre }}</router-link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import { Article } from "@/models/Article";
import ApiService from "@/services/ApiService";
import { getUrlApiForFiles, error, format_date } from "@/utils/utils";
import { defineComponent, ref, onMounted } from "vue";
import MenuProduit from "../Services/MenuProduit.vue";

export default defineComponent({
    name: 'ProduitTransfert',
    components: {
        MenuProduit
    },
    setup() {
        const articles = ref<Array<Article>>([]);

        function getTreeArticle() {
            return ApiService.get(`/actualite/articles/26`)
                .then(({ data }) => {
                    articles.value = data.data.data;
                    console.log(articles.value);
                    return data.data;
                })
                .catch(({ response }) => {
                    error(response.message)
                });
        }

        onMounted(() => {
            getTreeArticle();
        });

        return {
            articles,
            getUrlApiForFiles,
            format_date
        };
    },
});
</script>