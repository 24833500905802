<template>
  <div
    class="feature-wrap pt-20 pb-20"
    style="
      background-color: #fffbf0 !important;
      box-shadow: inset 0px 5px 10px -7px rgba(0, 0, 0, 0.5),
        /* Ombre interne supérieure */ inset 0px -5px 10px -5px
          rgba(0, 0, 0, 0.5);
    "
  >
    <div class="container">
      <div class="goal-content">
        <div class="content-title style1" style="margin-bottom: 30px">
          <span>SOLUTIONS</span>
          <h2>
            Nos Solutions <br />
            Digitales
          </h2>
        </div>
      </div>
      <div class="row justify-content-left">
        <!--<div class="col-xl-4 col-lg-6 col-md-6">
          <div class="feature-card style3">
            <div class="feature-info">
              <div class="feature-title">
                <span
                  ><img
                    src="../../assets/images/feature/feature-icon-1.png"
                    alt="Image"
                /></span>
                <h3>E-BOITE POSTALE</h3>
              </div>
              <p>Système de gestion des boites postales</p>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6">
          <div class="feature-card style3">
            <div class="feature-info">
              <div class="feature-title">
                <span
                  ><img
                    src="../../assets/images/feature/feature-icon-2.png"
                    alt="Image"
                /></span>
                <h3>LRE</h3>
              </div>
              <p>Lettre Recommandée au format Electronique</p>
            </div>
          </div>
        </div>-->

        <div
          class="col-xl-4 col-lg-6 col-md-6"
          v-for="(article, index) in articles"
          :key="index"
        >
          <router-link
            :to="{ name: 'ViewArticlePage', params: { id: article.id } }"
            class="link style1"
          >
            <div class="feature-card style3">
              <div class="feature-info">
                <div class="feature-title">
                  <span
                    ><img
                      :src="getUrlApiForFiles(article.urlImage)"
                      alt="Image"
                  /></span>
                  <h3>{{ article.titre }}</h3>
                </div>
                <p>{{ article.sousTitre }}</p>
              </div>
            </div>
          </router-link>
        </div>
        <!--<div class="col-xl-4 col-lg-6 col-md-6">
          <div class="feature-card style3">
            <div class="feature-info">
              <div class="feature-title">
                <span
                  ><img
                    src="../../assets/images/feature/feature-icon-3.png"
                    alt="Image"
                /></span>
                <h3>Poste Voyages</h3>
              </div>
              <p>
                Reservez facilement vos voyages facilement avec Poste Voyages
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6">
          <div class="feature-card style3">
            <div class="feature-info">
              <div class="feature-title">
                <span
                  ><img
                    src="../../assets/images/feature/feature-icon-3.png"
                    alt="Image"
                /></span>
                <h3>RapidPoste</h3>
              </div>
              <p>
                RapidPoste vous permet de confier vos courses aux facteurs de de
                La Poste du Bénin SA
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6">
          <div class="feature-card style3">
            <div class="feature-info">
              <div class="feature-title">
                <span
                  ><img
                    src="../../assets/images/feature/feature-icon-3.png"
                    alt="Image"
                /></span>
                <h3>RapidFacteur</h3>
              </div>
              <p>
                RapidFacteur est une application mobile pour les facteurs de La
                Poste du Bénin SA.
              </p>
            </div>
          </div>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch, nextTick } from "vue";
import ApiService from "../../services/ApiService";
import { error, success, getUrlApiForFiles } from "../../utils/utils";
import Swal from "sweetalert2";
import axios from "axios";

export default defineComponent({
  name: "FeatureComp",
  setup: () => {
    const articles = ref([]);

    const getAllArtcle = async () => {
      try {
        const art = await axios.get("/actualite/articles/73");
        console.log("LES ARTICLES SONT LES SUIVANTES ===> ", art);
        articles.value = art.data.data.data;

        console.log("LES ARTICLES===> ", articles.value);
      } catch (error) {
        //
      }
    };

    onMounted(async () => {
      await getAllArtcle();
    });

    return {
      articles,
      getUrlApiForFiles,
    };
  },
});
</script>