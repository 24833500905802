<template>
    <div class="blog-wrap pt-20 pb-20 bg-albastor">
        <div class="container">
            <div class="section-title style1 text-center mb-40">
                <span>Dernières informations de la Poste du Bénin SA</span>
                <h2>Nos derniers articles</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-6 col-md-6" v-for="(article, index) in truncatedArticles" :key="index">
                    <div class="blog-card style1">
                        <div class="blog-img"  style="height:277px;">
                            <img :src="getUrlApiForFiles(article.urlImage)" alt="Image" style="width: 100%; height:auto; object-fit: contain;">
                        </div>
                        <div class="blog-info">
                            <ul class="blog-metainfo  list-style">
                                <li><i class="flaticon-user"></i> <router-link to="/posts-by-author">La Poste du Bénin SA</router-link></li>
                                <li><i class="flaticon-calendar"></i>{{ format_date(article.datePublication)  }}</li>
                            </ul>
                            <h3><router-link :to="{ name: 'ViewArticlePage', params: { id: article.id } }">
                                {{ article.titre }}
                            </router-link></h3>
                            <router-link :to="{ name: 'ViewArticlePage', params: { id: article.id } }"  class="link style1">Lire plus
                                <i class="flaticon-right-arrow"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import ApiService from "@/services/ApiService";
import { getUrlApiForFiles, error,format_date } from "@/utils/utils";
import { defineComponent, ref, onMounted, computed } from "vue";
import { Article } from "@/models/Article";

export default defineComponent({
    name: 'BlogComp',
    components: {
    },

    setup(){

        const articles = ref<Array<Article>>([]);   

        function getTreeArticle() {
            return ApiService.get(`/tree/articles/22`)
                .then(({ data }) => {
                articles.value = data.data.data;
                console.log(articles.value);
                return data.data;
                })
                .catch(({ response }) => {
                error(response.message)
            });
        }
    
        onMounted(() => {
            getTreeArticle();
        });

        const truncatedArticles = computed(() => {
        const maxLength = 80; 
            return articles.value.map(article => {
                if (article.titre.length > maxLength) {
                return {
                    ...article,
                    titre: article.titre.substring(0, maxLength) + '...'
                };
                }
                return article;
            });
        });

        return { 
            articles,
            getUrlApiForFiles,
            format_date,
            truncatedArticles
        };
    },
});
</script>

<style scoped>
    .blog-card .blog-info h3 {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 15px;
    }
</style>